import styled from 'styled-components'

import backgroundDesktop from 'assets/images/video-background-desktop.png'
import Vector1 from 'assets/vectors/video-vector1.svg'
import Vector2 from 'assets/vectors/video-vector2.svg'

export const Container = styled.div`
  width: 100vw;
  height: 836px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${backgroundDesktop}),
    ${({ theme }) => theme.colors.tertiaryMedium};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      position: relative;
      height: fit-content;
      background: ${theme.colors.tertiaryMedium};
    }
  `}
`

export const AmoebaTop = styled(Vector1)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      top: 0px;
      right: 0px;
    }
  `};
`

export const AmoebaBottom = styled(Vector2)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      bottom: -29px;
      left: 0px;
    }
  `};
`

export const TitleContainer = styled.div`
  width: 720px;
  margin-bottom: 20px;
  h2 {
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 302px;
      margin-top: 60px;
      h2 {
        color: ${theme.fonts.heading3};
      }
    }
  `}
`

export const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  width: 800px;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-button-disabled {
    background: ${({ theme }) => theme.colors.neutralRegular};
    &:hover {
      background: ${({ theme }) => theme.colors.neutralRegular};
    }
  }

  .swiper-button-next-videos {
    position: absolute;
    top: 165px;
    right: -70px;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.smallTablet}) {
        top: unset;
        bottom: -100px;
        right: 300px;
      }
    `}
  }

  .swiper-button-prev-videos {
    position: absolute;
    top: 165px;
    left: -70px;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.smallTablet}) {
        top: unset;
        bottom: -100px;
        left: 300px;
      }
    `}
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  z-index: 2;
`

export const Card = styled.div`
  width: 720px;

  p {
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-bottom: 16px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 302px;
      margin-top: 45px;
      p {
        color: ${theme.fonts.mediumTextBold};
      }
    }
  `}
`
