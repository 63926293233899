import React from 'react'
import { SwiperSlide } from 'swiper/react'

import Carousel from 'components/Carousel'

import {
  Container,
  TitleContainer,
  CarouselContainer,
  CardsContainer,
  Card,
  AmoebaTop,
  AmoebaBottom,
} from './style'

const Videos = ({ title, videos, isMobile }) => {
  const renderCarousel = () => (
    <CarouselContainer>
      <Carousel
        primaryButton
        slidesPerView={1}
        spaceBetweenCards={10}
        nextButtonClass="videos"
        prevButtonClass="videos"
      >
        {videos.map((video) => (
          <SwiperSlide key={video.url}>
            <Card>
              <p>{video.title}</p>
              <iframe
                width="720"
                height="403"
                src={`https://www.youtube.com/embed/${video.url}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Card>
          </SwiperSlide>
        ))}
      </Carousel>
    </CarouselContainer>
  )

  const renderMobileCards = () => (
    <CardsContainer>
      {videos.map((video) => (
        <Card key={video.url}>
          <p>{video.title}</p>
          <iframe
            width="302"
            height="202"
            src={`https://www.youtube.com/embed/${video.url}`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Card>
      ))}
    </CardsContainer>
  )

  return (
    <Container>
      <AmoebaTop />
      <TitleContainer>
        <h2>{title}</h2>
      </TitleContainer>
      {typeof window !== 'undefined' && !isMobile && renderCarousel()}
      {typeof window !== 'undefined' && isMobile && renderMobileCards()}
      <AmoebaBottom />
    </Container>
  )
}

export default Videos
