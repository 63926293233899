import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      statments: allContentfulDepoimentosPaginaNossasHistorias {
        nodes {
          id
          title: titulo
          author: autor
          role: papelDoAutor
          locaticon: cidadeDoAutor
          story: depoimentoOuHistoria {
            story: depoimentoOuHistoria
          }
        }
      }
      videos: allContentfulVideosPaginaNossasHistorias {
        nodes {
          title: tituloDoVideo
          id
          url: linkDoVideo
        }
      }
    }
  `)
