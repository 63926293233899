import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import useOurStorys from 'graphql/useOurStorys'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/OurStories/Header'
import Statements from 'components/OurStories/Statements'
import Videos from 'components/Videos'

const OurStories = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  const data = useOurStorys()
  const statments = data.statments.nodes
  const videos = data.videos.nodes

  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.quaternaryDarkest }}>
        <Seo
          title="Nossas Histórias"
          description="Conheça os Moebitas e suas histórias, temos certeza que você vai se identificar com os relatos de superação de cada um deles."
        />
        <Header />
        <Statements data={statments} />
        <Videos title="Vídeos" videos={videos} isMobile={isMobile} />
      </Layout>
    </ThemeProvider>
  )
}
export default OurStories
