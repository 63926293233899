import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 1708px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};
`

export const Title = styled.p`
  ${({ theme }) => theme.fonts.largeBold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutralMedium};
  margin-bottom: 50px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextBlack};
    }
  `}
`

export const CardsContainer = styled.div`
  position: relative;
  height: 1385px;
  width: 1100px;
  display: flex;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-button-next-our-stories-statments {
    position: absolute;
    top: -93px;
    right: 76px;
  }

  .swiper-button-prev-our-stories-statments {
    position: absolute;
    top: -93px;
    left: 69px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 1000px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;

      .swiper-button-next-our-stories-statments {
        right: 46px;
      }

      .swiper-button-prev-our-stories-statments {
        left: 32px;
      }
  `}
`

export const Card = styled.div`
  width: 960px;
  height: 1385px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: 8px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 600px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 80%;
    }
  `}
`
export const CardTitle = styled.div`
  width: 100%;
  height: 218px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiaryMedium};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.backgroundLight};
    text-align: center;
    max-width: 680px;
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: unset;
      padding: 28px 0px;
      h3 {
        ${theme.fonts.heading4};
        max-width: 210px;
      }
    }
  `}
`

export const CardTextContainer = styled.div`
  padding: 66px 90px 33px 90px;
  height: 1167px;
  ${({ withScroll }) => withScroll && `overflow-y: scroll;`};
  box-sizing: border-box;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin-bottom: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: red;
    background: ${({ theme }) => theme.colors.neutralLight};
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.neutralRegular};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.neutralDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      overflow-y: scroll;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 46px 28px;
    }
  `}
`

export const Author = styled.p`
  ${({ theme }) => theme.fonts.mediumTextBlack};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutralDarkest};
  margin-bottom: 13px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextBlack};
    }
  `}
`

export const AuthorDescription = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.neutralDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
    }
  `}
`

export const AuthorLocation = styled.p`
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.neutralRegular};
  margin-top: 4px;
`

export const Story = styled.p`
  margin-top: 19px;
  ${({ theme }) => theme.fonts.mediumText};
  white-space: pre-line;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium};
    }
  `}
`
