import React from 'react'

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  LeftAmoeba,
  RightAmoeba,
} from './style'

const Header = () => (
  <Container>
    <TitleContainer>
      <LeftAmoeba />
      <span>Entendendo Melhor</span>
      <h1>Nossas Histórias</h1>
    </TitleContainer>
    <DescriptionContainer>
      <p>
        Conheça os Moebitas e suas histórias, temos certeza que você vai se
        identificar com os relatos de superação de cada um deles.
      </p>
      <RightAmoeba />
    </DescriptionContainer>
  </Container>
)

export default Header
