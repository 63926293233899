import React from 'react'
import { SwiperSlide } from 'swiper/react'

import Carousel from 'components/Carousel'

import {
  Container,
  Title,
  CardsContainer,
  Card,
  CardTitle,
  CardTextContainer,
  Author,
  AuthorDescription,
  AuthorLocation,
  Story,
} from './style'

const Statements = ({ data }) => (
  <Container>
    <Title>Depoimentos</Title>
    <CardsContainer>
      <Carousel
        primaryButton
        slidesPerView={1}
        spaceBetweenCards={60}
        nextButtonClass="our-stories-statments"
        prevButtonClass="our-stories-statments"
      >
        {data.map((item) => (
          <SwiperSlide id={item.id}>
            <Card>
              <CardTitle>
                <h3>{item.title}</h3>
              </CardTitle>
              <CardTextContainer withScroll={item.story.story.length > 1990}>
                <Author>{item.author}</Author>
                <AuthorDescription>{item.role}</AuthorDescription>
                <AuthorLocation>{item.locaticon}</AuthorLocation>
                <Story>{item.story.story}</Story>
              </CardTextContainer>
            </Card>
          </SwiperSlide>
        ))}
      </Carousel>
    </CardsContainer>
  </Container>
)
export default Statements
