import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/available-treatments-header-vector1.svg'
import Amoeba2 from 'assets/vectors/available-treatments-header-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 566px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.quaternaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: 700px;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: fit-content;
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  max-width: 407px;
  margin-right: 100px;
  margin-bottom: 50px;

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  h1 {
    position: relative;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 40px;
      margin-right: 0px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 90px;
      margin-bottom: 30px;

      h1 {
        ${theme.fonts.heading3};
        max-width: 200px;
        margin-top: 10px;
      }
    }
  `}
`

export const DescriptionContainer = styled.div`
  position: relative;
  max-width: 564px;
  height: fit-content;
  margin-bottom: 50px;

  p {
    position: relative;
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 40px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 0px;

      p {
        ${theme.fonts.smallTextSemiBold};
        max-width: 304px;
      }
    }
  `}
`

export const LeftAmoeba = styled(Amoeba1)`
  position: absolute;
  top: 48px;
  left: -124px;
  path {
    fill: ${({ theme }) => theme.colors.tertiaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 145px;
      width: 157px;
      top: 39px;
      left: unset;
      right: -7px;
    }
  `}
`
export const RightAmoeba = styled(Amoeba2)`
  position: absolute;
  right: -162px;
  bottom: -203px;
  path {
    fill: ${({ theme }) => theme.colors.tertiaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`
